exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-asortiman-jsx": () => import("./../../../src/pages/asortiman.jsx" /* webpackChunkName: "component---src-pages-asortiman-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-templates-blog-template-jsx": () => import("./../../../src/templates/blogTemplate.jsx" /* webpackChunkName: "component---src-templates-blog-template-jsx" */),
  "component---src-templates-category-template-jsx": () => import("./../../../src/templates/categoryTemplate.jsx" /* webpackChunkName: "component---src-templates-category-template-jsx" */),
  "component---src-templates-page-template-jsx": () => import("./../../../src/templates/pageTemplate.jsx" /* webpackChunkName: "component---src-templates-page-template-jsx" */),
  "component---src-templates-post-template-jsx": () => import("./../../../src/templates/postTemplate.jsx" /* webpackChunkName: "component---src-templates-post-template-jsx" */),
  "component---src-templates-tag-template-jsx": () => import("./../../../src/templates/tagTemplate.jsx" /* webpackChunkName: "component---src-templates-tag-template-jsx" */),
  "component---src-templates-user-template-jsx": () => import("./../../../src/templates/userTemplate.jsx" /* webpackChunkName: "component---src-templates-user-template-jsx" */)
}

